import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

import { map } from 'rxjs/operators';

import { TutorialComponent } from '../tutorial/tutorial.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  signInLabel: string;
  runningGames = [];
  completedGames = [];

  private user;
  private dialogRef;

  constructor(private auth: AngularFireAuth, private fs: AngularFirestore,
    private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.user = user;
      if (!user) {
        this.signInLabel = 'Sign in with Google';
        return;
      }

      if (user.isAnonymous) {
        this.signInLabel = 'Sign in with Google';
      } else {
        this.signInLabel = 'Sign out';
      }

      this.fs.collection(
        'games', ref => ref.where('playerIds', 'array-contains', user.uid))
          .snapshotChanges().subscribe(actions => {
            this.runningGames = [];
            this.completedGames = [];
            actions.forEach(action => {
              const id = action.payload.doc.id;
              const data = action.payload.doc.data();
              const game = {id, ...data};
              if (game['state'] === 'running') {
                this.runningGames.push(game);
              } else if (game['state'] === 'completed') {
                this.completedGames.push(game);
              }
            });
          });
    });
  }

  gameTitle(game) {
    const players = game['players'].map(player => player['name']);
    return players.join(', ');
  }

  showTutorial() {
    this.dialogRef = this.dialog.open(TutorialComponent,
    { width: '90%', height: '80%' });
  }

  playGame(id: string) {
    this.router.navigate(['/play'], {queryParams: {gameId: id}});
  }

  replayGame(id: string) {
    this.router.navigate(['/replay'], {queryParams: {gameId: id}});
  }

  signIn() {
    if (!this.user || this.user.isAnonymous) {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.auth.auth.signInWithRedirect(provider);
    } else {
      this.auth.auth.signOut();
    }
  }
}
