import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input,
  OnInit, Output, ViewChild } from '@angular/core';

import { Drawing, Line, Point } from '../model';

@Component({
  selector: 'lii-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css']
})
export class CanvasComponent implements AfterViewInit, OnInit {

  @ViewChild('canvas') canvas: ElementRef;

  @Input() drawing: Drawing;
  @Output() lineAdded = new EventEmitter<Line>();
  @Input() mode: string;
  @Input() color: string = 'black';

  currentLines = new Map<number, Line>();

  private tStart: number;
  private t: number;
  private context: CanvasRenderingContext2D;
  private boundingRect;
  private timer;
  private playing = false;
  played = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.canvas.nativeElement.width = 350;
    this.canvas.nativeElement.height = 350;
    this.context = this.canvas.nativeElement.getContext('2d');
    this.context.lineWidth = 2;
    this.context.lineCap = 'round';
    this.context.strokeStyle = '#000';
  }

  clear() {
    if (this.context) {
      this.context.clearRect(0, 0, this.boundingRect.width, this.boundingRect.height);
    }
  }

  play() {
    if (this.mode === 'playback') {
      if (!this.boundingRect) {
        this.boundingRect = this.canvas.nativeElement.getBoundingClientRect();
      }
      this.played = true;
      if (!this.playing) {
        this.tStart = Date.now();
      } else {
        // Fast forward to end
        this.tStart = 0;
      }
      this.playing = !this.playing;
      window.requestAnimationFrame(() => this.paint());
    }
  }

  private createPoint(targetTouch): Point {
    const point = new Point(Date.now() - this.tStart,
      targetTouch.clientX - this.boundingRect.left,
      targetTouch.clientY - this.boundingRect.top);
    return point;
  }

  onTouchStart(event) {
    if (this.mode !== 'input') {
      return;
    }
    if (!this.boundingRect) {
      this.boundingRect = this.canvas.nativeElement.getBoundingClientRect();
    }
    if (!this.drawing) {
      this.drawing = new Drawing();
      this.tStart = Date.now();
    }
    for (let touch of event.changedTouches) {
      const line = new Line();
      line.color = this.color;
      line.points.push(this.createPoint(touch));
      this.drawing.lines.push(line);
      this.currentLines.set(touch.identifier, line);
    }
  }

  onTouchMove(event) {
    if (this.mode !== 'input') {
      return;
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    for (let touch of event.targetTouches) {
      const line = this.currentLines.get(touch.identifier);
      line.points.push(this.createPoint(touch));
    }
    this.paint();
  }

  onTouchEnd(event) {
    if (this.mode !== 'input') {
      return;
    }
    for (let touch of event.changedTouches) {
      const line = this.currentLines.get(touch.identifier);
      line.points.push(this.createPoint(touch));
      this.lineAdded.emit(line);
      this.currentLines.delete(touch.identifier);
    }
    this.paint();
  }

  paint() {
    if (!this.boundingRect) {
      this.boundingRect = this.canvas.nativeElement.getBoundingClientRect();
    }
    const speed = 2;
    const maxT = (this.mode === 'playback')
      ? (Date.now() - this.tStart)
      : Number.MAX_SAFE_INTEGER;
    this.context.clearRect(0, 0, this.boundingRect.width, this.boundingRect.height);
    for (let line of this.drawing.lines) {
      if (line.points.length < 2) {
        continue;
      } else if (line.points[0].t / speed > maxT) {
        continue;
      }
      this.context.strokeStyle = line.color;
      this.context.beginPath();
      this.context.moveTo(line.points[0].x, line.points[0].y);
      for (let i = 1; i < line.points.length; i++) {
        if (line.points[i].t / speed > maxT) {
          break;
        }
        this.context.lineTo(line.points[i].x, line.points[i].y);
      }
      this.context.stroke();
    }

    if (this.mode === 'playback' && this.playing) {
      window.requestAnimationFrame(() => this.paint());
    }
  }
}
