import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-replay',
  templateUrl: './replay.component.html',
  styleUrls: ['./replay.component.css']
})
export class ReplayComponent implements OnInit {

  gameId: string;
  game;
  chains;

  playerNames = new Map<string, string>();

  constructor(private route: ActivatedRoute, private fs: AngularFirestore,
    private router: Router) {
    route.queryParamMap.subscribe(params => {
      this.gameId = params.get('gameId');
      const gameRef = this.fs.collection('games').doc(this.gameId);
      gameRef.valueChanges().pipe(first())
        .subscribe(game => {
          this.game = game;
          this.game.players.forEach(player => {
            this.playerNames.set(player.uid, player.name);
          });
        });
      gameRef.collection('chains').valueChanges().subscribe(chains => {
        this.chains = chains;
        this.updateUi();
      });
    });
  }

  ngOnInit() {

  }

  updateUi() {

  }

  playerName(uid: string): string {
    return this.playerNames.get(uid);
  }

  startNewGame() {
    this.router.navigate(['/']);
  }
}
