import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { Player } from './model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private auth: AngularFireAuth, private fs: AngularFirestore,
    private route: ActivatedRoute, private router: Router) {}

  private chatBoxRef: ElementRef;
  @ViewChild('chatBox') set chatBox(content: ElementRef<any>) {
    this.chatBoxRef = content;
  }

  fullscreen = false;
  chatText = [];
  chatEntry = '';

  private userId: string;
  private player: object;
  private gameId: string;
  private subscription;
  private chats: object[];
  private chatBoxShown = false;
  private chatColors = new Map<string, string>();
  private chatListener;

  ngOnInit() {
    this.auth.user.subscribe(user => {
      if (!user) {
        this.auth.auth.signInAnonymously();
      } else {
        this.userId = user.uid;
      }
    });

    this.route.queryParamMap.subscribe(queryParam => {
      const gameId = queryParam.get('gameId');
      if (gameId !== this.gameId) {
        this.gameId = gameId;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }

        if (!this.gameId) {
          this.chatBoxShown = false;
          return;
        }

        this.subscription = this.fs.collection('games').doc(this.gameId).valueChanges().subscribe(game => {
          const oldChatLength = this.chats ? this.chats.length : 0;
          const firstChat = !this.chats;
          this.chats = game['chats'] || [];
          if (!firstChat && oldChatLength !== this.chats.length) {
            this.chatBoxShown = true;
          }
          game['players'].forEach(player => {
            if (player['uid'] === this.userId) {
              this.player = player;
            }
            this.chatColors.set(player['uid'], player['color']);
          });
          this.showNextChat();
        });
      }
    });

    const fullScreenChange = () => {
      this.fullscreen = (document['webkitIsFullScreen']
        || document['mozFullScreen']
        || document['msFullscreenElement']) == true;
    };
    addEventListener('webkitfullscreenchange', fullScreenChange, false);
    addEventListener('mozfullscreenchange', fullScreenChange, false);
    addEventListener('fullscreenchange', fullScreenChange, false);
    addEventListener('MSFullscreenChange', fullScreenChange, false);
  }

  chatClick(event) {
    if (this.chatBoxShown && this.chatBoxRef) {
      let element = event.target;
      let hit = false;
      while (element) {
        if (this.chatBoxRef.nativeElement === element) {
          hit = true;
          break;
        }
        element = element.parentElement;
      }

      if (!hit) {
        this.chatBoxShown = false;
        window.removeEventListener('click', this.chatListener);
        this.chatListener = null;
      }
    }
  }

  goHome() {
    this.router.navigate(['/']);
  }

  goFullScreen() {
    const elem = document.documentElement;
    const fullscreenMethod = elem.requestFullscreen ||
      elem['webkitRequestFullscreen'] || elem['mozRequestFullscreen']
      || elem['msRequestFullscreen'];
    if (fullscreenMethod) {
      fullscreenMethod.call(elem);
    }
  }

  get activeClass(): string {
    return this.chatBoxShown ? 'active' : '';
  }

  private showNextChat() {
    if (this.chats) {
      this.chatText = this.chats.slice(-3);
    }
  }

  toggleChat() {
    this.chatBoxShown = !this.chatBoxShown;
    if (this.chatBoxShown) {
      this.chatListener = (e) => { this.chatClick(e) };
      setTimeout(() => {
        window.addEventListener('click', this.chatListener);
      }, 0);
    }
  }

  chatColor(chat) {
    return this.chatColors.get(chat.playerId);
  }

  enter() {
    const message = `${this.player['name']}: ${this.chatEntry}`;
    this.chatEntry = '';
    this.fs.firestore.runTransaction(transaction => {
      const gameRef = this.fs.collection('games').doc(this.gameId).ref;
      return transaction.get(gameRef).then(game => {
        let chats = game.data().chats;
        if (!chats) {
          chats = [];
        }
        chats.push({
          playerId: this.player['uid'],
          message: message,
        });
        return transaction.update(gameRef, {
          chats: chats,
        });
      });
    });

  }
}
