import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';

import { MatDialogRef, MatTabGroup } from '@angular/material';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  @ViewChild('matTabGroup') matTabGroup: ElementRef<MatTabGroup>;

  buttonLabel = 'next';
  index = 0;

  readonly indices = [0, 1, 2, 3];
  readonly maxIndex = this.indices[this.indices.length - 1];

  constructor(public dialogRef: MatDialogRef<TutorialComponent>) { }

  ngOnInit() {
  }

  next() {
    if (this.index === this.maxIndex) {
      this.dialogRef.close();
    } else {
      this.index++;
      this.indexChange();
    }
  }

  indexChange() {
    if (this.index < this.maxIndex) {
      this.buttonLabel = 'next';
    } else {
      this.buttonLabel = 'done';
    }
  }

  setIndex(i: number) {
    this.index = i;
    this.indexChange();
  }

  oClass(i: number) {
    return (i === this.index) ? 'filled' : 'empty';
  }

  opacity(index: number) {
    if (this.index === index) {
      return 1;
    } else {
      return 0;
    }
  }
}
