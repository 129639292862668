import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CreateGameComponent } from './create-game/create-game.component';
import { CastComponent } from './cast/cast.component';
import { HomeComponent } from './home/home.component';
import { JoinGameComponent } from './join-game/join-game.component';
import { PlayGameComponent } from './play-game/play-game.component';
import { ReplayComponent } from './replay/replay.component';
import { WaitingComponent } from './waiting/waiting.component';

const routes: Routes = [
  { path: 'create', component: CreateGameComponent },
  { path: 'join', component: JoinGameComponent },
  { path: 'play', component: PlayGameComponent },
  { path: 'replay', component: ReplayComponent },
  { path: 'wait', component: WaitingComponent },
  { path: 'cast', component: CastComponent },
  { path: '', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
