import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatDialogModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTabsModule,
} from '@angular/material';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateGameComponent } from './create-game/create-game.component';
import { HomeComponent } from './home/home.component';
import { JoinGameComponent } from './join-game/join-game.component';
import { PlayGameComponent } from './play-game/play-game.component';
import { CanvasComponent } from './canvas/canvas.component';
import { ReplayComponent } from './replay/replay.component';
import { WaitingComponent } from './waiting/waiting.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { CastComponent } from './cast/cast.component';

@NgModule({
  declarations: [
    AppComponent,
    CreateGameComponent,
    HomeComponent,
    JoinGameComponent,
    PlayGameComponent,
    CanvasComponent,
    ReplayComponent,
    WaitingComponent,
    TutorialComponent,
    CastComponent
  ],
  entryComponents: [
    TutorialComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTabsModule,
  ],
  providers: [
    { provide: FunctionsRegionToken, useValue: 'us-central1' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
