import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

import { CanvasComponent } from '../canvas/canvas.component';
import { Drawing, Game, Line, Player } from '../model';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.css']
})
export class WaitingComponent implements OnInit {

  @ViewChild('liiCanvas') canvas: ElementRef<CanvasComponent>;

  userId: string;
  playerName: string;
  color: string;
  gameId: string;
  game: Game;
  inviteCode = 'loading...';
  players: Player[];
  isOwner = false;
  startDisabled = false;
  drawing: Drawing;

  constructor(private route: ActivatedRoute, private fs: AngularFirestore,
    private auth: AngularFireAuth, private functions: AngularFireFunctions,
    private router: Router) {
    auth.user.subscribe(user => {
      this.userId = user.uid;
      this.updatePlayerName();
    });
    route.queryParamMap.subscribe(queryParams => {
      this.gameId = queryParams.get('gameId');
      fs.collection('games').doc(this.gameId).valueChanges().subscribe(game => {
        this.game = Game.fromDoc(this.gameId, game);
        if (this.game.state === 'running') {
          this.goToGame();
          return;
        }
        this.inviteCode = this.game.inviteCode;
        this.players = this.game.players;
        const doodle = game['doodle'] as Drawing;
        if (doodle) {
          const repaint =
            (!this.drawing || doodle.lines.length !== this.drawing.lines.length);
          this.drawing = doodle;
          if (repaint && this.canvas) {
            const canvas = this.canvas;
            setTimeout(() => {
              canvas['paint']();
            }, 0);
          }
        }
        this.updatePlayerName();
      });
    });
  }

  ngOnInit() {
  }

  lineAdded(line: Line) {
    const gameRef = this.fs.collection('games').doc(this.gameId).ref;
    if (!this.drawing) {
      this.drawing = new Drawing();
    }
    this.drawing.lines.push(line);
    this.fs.firestore.runTransaction(transaction => {
      return transaction.get(gameRef).then(game => {
        let drawing = game.data().doodle;
        if (!drawing) {
          drawing = new Drawing().toObject();
        }
        drawing.lines.push(line.toObject());
        transaction.update(gameRef, {
          doodle: drawing,
        });
      });
    });
  }

  private updatePlayerName() {
    if (this.userId && this.game) {
      this.isOwner = this.game.owner === this.userId;
      for (let player of this.game.players) {
        if (player.id === this.userId) {
          this.playerName = player.name;
          this.color = player.color;
          return;
        }
      }
    }
  }

  startGame() {
    this.startDisabled = true;
    this.functions.httpsCallable('startGame')({gameId: this.gameId}).subscribe(res => {
      this.goToGame();
    });
  }

  private goToGame() {
    this.router.navigate(['/play'], {queryParams: {gameId: this.gameId}});
  }

  get canShare(): boolean {
    const nav: any = window.navigator;
    return nav && nav.share;
  }

  shareCode() {
    const nav: any = window.navigator;
    if (nav && nav.share) {
      nav.share({
        url: '/join?code=' + this.inviteCode,
        text: 'Join my Lost in Illustration game!'
      });
    }
  }

}
