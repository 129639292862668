import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';


@Component({
  selector: 'app-join-game',
  templateUrl: './join-game.component.html',
  styleUrls: ['./join-game.component.css']
})
export class JoinGameComponent implements OnInit {

  joiningGame = false;
  name: string;
  inviteCode: string;
  message: string;

  constructor(private fs: AngularFirestore, private functions: AngularFireFunctions,
    private route: ActivatedRoute, private router: Router) {
    route.queryParamMap.subscribe(params => {
      this.inviteCode = params.get('code');
    });
  }

  ngOnInit() {
  }

  joinDisabled(): boolean {
    return !this.name || this.joiningGame;
  }

  joinGame() {
    this.joiningGame = true;
    this.inviteCode = this.inviteCode.toUpperCase();
    this.message = 'Joining...';
    const request = {
      inviteCode: this.inviteCode,
      name: this.name,
    };
    this.functions.httpsCallable('joinGame')(request)
      .subscribe(result => {
        const gameId = result['game_id'];
        const state = result['state'];
        const joined = result['joined'];
        if (joined) {
          if (state === 'inviting') {
            this.router.navigate(['/wait'], {queryParams: {gameId: gameId}});
          } else {
            this.router.navigate(['/play'], {queryParams: {gameId: gameId}});
          }
        } else {
          if (state !== 'inviting') {
            this.message = 'Game is no longer available to join';
          }
        }
      }, error => {
        this.joiningGame = false;
        this.message = 'Failed to join game, did you get the invite code right?';
      });
  }
}
