import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-create-game',
  templateUrl: './create-game.component.html',
  styleUrls: ['./create-game.component.css']
})
export class CreateGameComponent implements OnInit {

  private userId: string;

  ownerName: string;
  message: string;
  waitingForCreate = false;

  constructor(private functions: AngularFireFunctions,
    private auth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.userId = user.uid;
    });
  }

  createDisabled() {
    return !this.ownerName || this.waitingForCreate;
  }

  createGame() {
    this.waitingForCreate = true;
    this.message = 'Creating game...';
    this.functions.httpsCallable('createGame')({ownerName: this.ownerName}).subscribe(game => {
      this.router.navigate(['/wait'], {queryParams: {gameId: game['id']}});
    });
  }
}
