export class Point {
  constructor(readonly t: number, readonly x: number, readonly y: number) {}
}

export class Line {
  points: Point[] = [];
  color: string;

  toObject(): object {
    return {
      points: this.points.map(point => Object.assign({}, point)),
      color: this.color,
    };
  }
}

export class Drawing {
  lines: Line[] = [];

  toObject(): object {
    return {
      lines: this.lines.map(line => line.toObject())
    };
  }
}

export class Player {
  constructor(readonly id: string, readonly name: string,
    readonly color: string) {}

  static fromDoc(doc: object) {
    return new Player(doc['uid'], doc['name'], doc['color']);
  }
}

export class Game {
  players: Player[] = [];

  constructor(readonly id: string, readonly owner: string,
    readonly inviteCode: string, readonly state: string) {}

  static fromDoc(id: string, doc: object): Game {
    const game = new Game(id, doc['owner_id'], doc['invite_code'], doc['state']);
    game.players = doc['players'].map(playerDoc => Player.fromDoc(playerDoc));
    return game;
  }
}