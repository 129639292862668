import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { CanvasComponent } from '../canvas/canvas.component';
import { Drawing, Game, Player } from '../model';

class Round {
  constructor(readonly playerUid: string, readonly type: string,
    readonly text: string, readonly drawing: Drawing) {}
}

class Chain {
  constructor(readonly id: string, readonly rounds: Round[]) {}
}

@Component({
  selector: 'app-play-game',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.css']
})
export class PlayGameComponent implements OnInit {

  @ViewChild('liiCanvas') canvas: ElementRef<CanvasComponent>;

  userId: string;
  gameId: string;

  chains: Chain[];

  chainIndex: number;
  roundIndex = -1;
  mode: string;

  text = '';
  drawing: Drawing;
  canvasMode: string;
  blocker: Player;

  submitting = false;

  private players = new Map<string, Player>();

  constructor(private route: ActivatedRoute, private fs: AngularFirestore,
    private auth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.userId = user.uid;
    });
    this.route.queryParamMap.subscribe(paramsMap => {
      this.gameId = paramsMap.get('gameId');
      this.fs.collection('games').doc(this.gameId).valueChanges().subscribe(game => {
        game['players'].forEach(player => {
          this.players.set(player.uid, player);
        });
      });
      this.fs.collection('games').doc(this.gameId).collection('chains').snapshotChanges()
        .subscribe(actions => {
          this.chains = actions.map(action => {
            return new Chain(action.payload.doc.id,
              action.payload.doc.data().rounds.map(round => round as Round));
          });
          this.findCurrentRound();
        });
    });
  }

  get title(): string {
    if (this.drawingMode) {
      return 'Draw this phrase: ' + this.text;
    }
    return '';
  }

  get drawingMode(): boolean {
    return this.mode === 'draw';
  }

  get textMode(): boolean {
    return this.mode === 'text';
  }

  get waitingForPrevious(): boolean {
    return this.mode === 'waiting';
  }

  get done(): boolean {
    return this.mode === 'done';
  }

  get submitDisabled(): boolean {
    if (this.submitting || this.waitingForPrevious) {
      return true;
    }
    if (this.mode === 'text' && this.text === '') {
      return true;
    }
    if (this.done) {
      return true;
    }
    return false;
  }

  get textValid(): boolean {
    return this.text !== '';
  }

  get playersArray(): Player[] {
    return Array.from(this.players.values());
  }

  private findCurrentRound() {
    const count = this.chains.length;
    let currentRound: Round = null;
    let previousRound: Round = null;
    let newRound = false;
    for (let r = 0; r < count; r++) {
      for (let c = 0; c < count; c++) {
        const round = this.chains[c].rounds[r];
        if (round.playerUid !== this.userId) {
          continue;
        }
        if ((round.type === 'draw' && !round.drawing)
          || (round.type === 'text' && !round.text)) {
          currentRound = round;
          this.chainIndex = c;
          if (r != this.roundIndex) {
            newRound = true;
          }
          this.roundIndex = r;
          this.mode = round.type;
          if (r > 0) {
            previousRound = this.chains[c].rounds[r - 1];
          }
          break;
        }
      }
      if (currentRound) {
        break;
      }
    }

    if (!currentRound) {
      // All done!
      this.drawing = null;
      this.mode = 'done';
      this.router.navigate(['/replay'], {queryParams: {gameId: this.gameId}});
    }

    if (this.mode === 'draw') {
      this.text = previousRound ? previousRound.text : null;
      if (this.text == null) {
        this.mode = 'waiting';
        this.blocker = this.players.get(previousRound.playerUid);
      }
      this.canvasMode = 'input';
      if (newRound) {
        this.drawing = null;
      }
    } else if (this.mode === 'text') {
      this.drawing = previousRound ? previousRound.drawing : null;
      if (previousRound && this.drawing == null) {
        this.mode = 'waiting';
        this.blocker = this.players.get(previousRound.playerUid);
      }
      this.canvasMode = 'playback';
      if (newRound) {
        this.text = '';
      }
    }
  }

  submit() {
    if (this.mode === 'text') {
      this.submitText();
    } else if (this.mode === 'draw') {
      this.submitDrawing();
    }
  }

  private submitText() {
    this.submitting = true;
    const chainRef = this.fs.collection('games').doc(this.gameId).collection('chains')
      .doc(this.chains[this.chainIndex].id).ref;
    this.fs.firestore.runTransaction(transaction => {
      return transaction.get(chainRef).then(chainSnapshot => {
        const chain = chainSnapshot.data();
        const rounds = chain.rounds;
        rounds[this.roundIndex].text = this.text;
        return transaction.set(chainRef, chain);
      });
    }).then(() => {
      this.submitting = false;
    });
  }

  private submitDrawing() {
    this.submitting = true;
    const chainRef = this.fs.collection('games').doc(this.gameId).collection('chains')
      .doc(this.chains[this.chainIndex].id).ref;
    this.fs.firestore.runTransaction(transaction => {
      return transaction.get(chainRef).then(chainSnapshot => {
        const chain = chainSnapshot.data();
        const rounds = chain.rounds;
        rounds[this.roundIndex].drawing = this.canvas['drawing'].toObject();
        return transaction.set(chainRef, chain);
      });
    }).then(() => {
      this.submitting = false;
      this.canvas['clear']();
    });
  }
}
